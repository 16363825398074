/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.modal-1{
  .modal-wrapper{
    height: 80%;
    width: 80%;
    top: 10%;
    position: absolute;
    display: block;
    border-radius: 20px;
 }
}

.modal-2{
  .modal-wrapper{
    height: 80%;
    width: 100%;
    top: 10%;
    position: absolute;
    display: block;
    border-radius: 20px;
 }
}

.esq {
  float: right;
  text-align: right;
}

ion-select {
  --padding-start: 0px;
}

ion-title {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background-color: #5bc299;
}

.fundoLogo {
  background-color: #ffffff;
  margin-top: 0px;
}

.imagem-avatar {
  width: 60% !important;
  height: 60% !important;
  margin: auto !important;
  display: block !important;
  margin-top: 25px !important;
}

.total {
  background-color: #88D3B4;
  margin-left: 10px;
  margin-right: 10px;
}

.item {
  --background: transparent !important;
}

.item .item-native {
   --background: transparent !important;
}

.naoPago {
  color: #000000;
  // font-weight: bold;
  background-color: rgb(242, 138, 93);
}

.pago {
  color: #000000;
  // font-weight: bold;
  background-color: rgb(135, 244, 186);
}

.meioPago {
  color: #000000;
  // font-weight: bold;
  background-color: #eed06f!important;
}

.ativo {
  color: #b5e2f5;
  font-weight: bold;
  background-color: rgb(232, 249, 240);
}

.inativo {
  color: #ffffff;
  font-weight: bold;
  background-color: rgb(201, 230, 240);
}

.debito {
  color: red;
  text-align: right;
}

.credito {
  color: blue;
  text-align: right;
}

.imagem-center {
  //height: 70vh !important;
  width: 100% !important;
  margin: auto !important;
  display: block !important;
}

.imagem-center2 {
  width: 80% !important;
  margin: auto !important;
  display: block !important;
}

.imagem-produto {
  //height: 70vh !important;
  width: 90% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  display: block !important;
  margin-top: 5px;
}

.favorito {
  color:#ffffff;
  background-color: #6b0fc5;
  margin-top: 5px !important;
  margin-right: auto !important;
  width: 25px;
  height: 25px;
}

.badge-one {
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 0.6em;
  --padding-start: 5px;
  --padding-end: 5px;
}

.badge-two {
  position: absolute;
  top: 0px;
  right: -5px;
  font-size: 0.6em;
  --padding-start: 3px;
  --padding-end: 3px;
}

.fundo-titulo {
  background-color: #5bc299;
}

.botao-menu {
  color:#ffffff;
}

.subtitulo {
  background-color: #5bc299;
  margin-top: 0px;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: center;
}

.subtitulo2 {
  color: #5bc299;
  background-color: #ffffff;
  margin-top: 0px;
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.fundo {
  // --background: url("/assets/imagens/bg-login.png") 0 0/100% 100% no-repeat;
  // background-size: cover;
  // background-position: center;
  // --background-color: #0a91df!important;
  --ion-background-color:#B39CD0;
}

.img {
  max-width:100%;
  box-shadow:10px 10px 10px #888888;
}

.ligar {
  width: 25% !important;
  margin: auto !important;
  display: block !important;
}

.select-modal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 30% 10%  !important;
}

.primeiro {
  padding: 0;
  margin-top: -2%;
  width:100%;
}

.round {
  max-height: 22px;
  border-radius: 25px !important;
  padding-left: 30px !important;
  font-size: 130%;
  margin-bottom: 5px;
  border: 1px solid #ffffff;
  border-bottom: 0px !important;
  box-shadow: none !important;
  opacity: 0.3;
}

.logoEntrada {
  width: 90%;
  opacity: 0.7;
  // margin: auto !important;
  margin-top: 10px;
  // display: block !important;
}

.textoBranco {
  color: #ffffff;
}

.imagem-center {
  width: 80% !important;
  margin: auto !important;
  display: block !important;
}

.modal-recuado .modal-wrapper {
   height: 80%;
   width: 80%;
   top: 10%;
   position: absolute;
   display: block;
   border-radius: 20px;
 }

 .modal2 .modal-wrapper {
    height: 50%;
    width: 80%;
    top: 25%;
    position: absolute;
    display: block;
    border-radius: 20px;
 }

 ion-icon {
   &.small {
      margin-top: 2px;
      margin-right: 5px;
      width: 22px;
      height: 22px;
   }
   &.medio {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    width: 25px;
    height: 25px;
  }
   &.normal {
     margin-top: 5px;
     margin-right: 5px;
     margin-left: 5px;
     width: 35px;
     height: 35px;
   }
   &.big {
     margin-top: 5px;
     margin-left: 10px;
     width: 35px;
     height: 35px;
   }

   &.bigger {
     width: 100px;
     height: 100px;
   }

   &.red {
     color: red;
   }
 }

 .categoria {
   border-radius: 12px !important;
   overflow: hidden;
 }

ion-menu {
  --ion-background-color: var(--ion-color-primary-sidemenu-background);
  --ion-text-color: var(--ion-color-primary-contrast);
}
